<template>
  <div class="flex flex-col gap-y-4 pb-4 header-shadow">
    <div class="flex flex-row items-center justify-between pt-[1.875rem] pb-[1.125rem] px-10">
      <LocalizedLink v-umami="'bed_exchange_logo_header_desktop'" to="/">
        <BedExchange />
      </LocalizedLink>
      <div class="flex flex-row gap-x-7 items-center">
        <LocalizedLink v-umami="'register_host_button_header_desktop'" to="/signUp/host">
          <MyHeaderCTA>
            {{ $t('index.cta.host') }}
          </MyHeaderCTA>
        </LocalizedLink>
        <LocalizedLink v-umami="'register_guest_button_header_desktop'" to="/signUp/guest">
          <MyHeaderCTA>
            {{ $t('index.cta.guest') }}
          </MyHeaderCTA>
        </LocalizedLink>

        <LanguageSwitcher />

        <LocalizedLink v-umami="'profile_button_header_desktop'" to="/profile">
          <Icon class="text-4xl" name="iconamoon:profile-circle" />
        </LocalizedLink>
      </div>
    </div>
    <div class="h-0 border border-details_light_grey" />
    <div class="flex flex-row gap-x-10 pr-10 justify-end text-xl font-bold">
      <LocalizedLink v-umami="'home_header_desktop'" to="/">
        {{ $t('header.home') }}
      </LocalizedLink>
      <LocalizedLink v-umami="'about_us_header_desktop'" to="/AboutUs">
        {{ $t('header.about_us') }}
      </LocalizedLink>
      <LocalizedLink v-umami="'how_it_works_header_desktop'" to="/HowItWorks">
        {{ $t('header.how_it_works') }}
      </LocalizedLink>
      <LocalizedLink v-umami="'faq_header_desktop'" to="/faq">
        {{ $t('header.faq') }}
      </LocalizedLink>
      <LocalizedLink v-umami="'contact_us_header_desktop'" to="/ContactUs">
        {{ $t('header.contact_us') }}
      </LocalizedLink>
    </div>
  </div>
</template>
